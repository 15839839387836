
class ContactUs{

    constructor(){
        this.initDialog();
    }

    show(){
        if (typeof ShopifyApp !== 'undefined' && typeof ShopifyAppBridge !== 'undefined') { //默认嵌入弹窗
            const Actions = ShopifyAppBridge.actions;
            const { Button, Modal } = Actions;

            const helpOKButton = Button.create(ShopifyApp, {label: 'I understand'});
            const helpModalOptions = {
                title: 'Customer Service!',
                message: 'If you have any question, please contact us: contact@writereadsoftware.com',
                footer: {
                    buttons: {
                        primary: helpOKButton
                    }
                }
            }
            const helpModal = Modal.create(ShopifyApp, helpModalOptions);

            helpModal.dispatch(Modal.Action.OPEN);
            helpOKButton.subscribe(Button.Action.CLICK, () => {
                helpModal.dispatch(Modal.Action.CLOSE);
            });
        } else {
            Swal.fire({
                title: '',
                html: 'If you have any question, please contact us: <b>contact@writereadsoftware.com</b>',
                type: 'info',
                showCancelButton: false,
                confirmButtonText: 'OK, I know!',
            });
        }
    }

    initDialog(){
        if (alertify.contactUs) {
            return;
        }
        alertify.dialog('contactUs', function factory(){
            return {
                main:function(message){
                    this.message = message;
                },
                prepare: function(){
                    this.setHeader('Customer Service');
                    const html =  `<div class="contact-us">
<h4><i class="iconfont i-question-o"></i> If you have any question, please contact us:</h4>`
                        + `<div class="contact-email"><a href="mailto:contact@writereadsoftware.com">contact@writereadsoftware.com</a></div></div>`;
                    return this.setContent(html);
                },
                setup: function(){
                    return {
                        options: {
                            resizable: false,
                            pinned: true,
                            maximizable: false,
                            startMaximized: false,
                            autoReset: false,
                            movable:false
                        }
                    }
                }
            };
        });
    }
}

export default ContactUs;