import lockBtn from './button-lock.js';
import Upload from './upload.js';

class Ticket{

    constructor($ticketForm) {
        this.$ticketForm = $ticketForm;
        this.initFormSubmit();
        this.initUpload();
    }

    initUpload(){
        this.$attachment = this.$ticketForm.find(':file');
        this.$attachment.on('change', function(value){
            console.log(value);
        });
    }
    initFormSubmit(){
        this.$submitBtn = this.$ticketForm.find(':submit');
        const lock = lockBtn(this.$submitBtn);
        this.$ticketForm.on('submit',  (e) => {
            e.preventDefault();
            if (lock.isDisabled()) {
                return false;
            }
            lock.lock();
            const params = this.$ticketForm.serialize();
            $.post(this.$ticketForm.attr('action'), params, function (response) {
                if (response.success) {
                    Swal.fire({
                        title: "<span style='text-align:center; font-size: 18px; color: #575757;'>Your question has been saved and we will email you as soon as possible</span>",
                        confirmButtonColor: "#5264BB",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        type: "success"
                    });
                    setTimeout(function () {
                        if (window._dashUrl) {
                            location.href = window._dashUrl;
                        } else {
                            location.reload();
                        }
                    }, 2000);
                }
            }, 'JSON');
        });
        return false;
    }
}

export default Ticket;