import "../scss/app.scss";

import 'bootstrap-switch/dist/js/bootstrap-switch.js'
import '../../../admin/adminlte/js/adminlte.js';
import '../../../admin/adminlte/js/demo.js';
import 'form-serializer';
import Ticket from "./component/ticket.js";
import ContactUs from "./component/contact-us.js";

class Application{

    constructor() {
        this.booted = false;
        this.$document = $(document);
        this.init();
        this.boot();
    }

    init(){
        this.initPjax();
        // 联系我们
        const $contactUs = $('#contact-us');
        $contactUs.length > 0 && this.initContactUs($contactUs);
        this.$document.trigger('qimify.init');
    }

    boot() {
        if (this.booted) {
            return;
        }
        // 登录框
        const $installAppForm = $('.install-app form');
        $installAppForm.length > 0 && this.initLoginForm($installAppForm);
        // 添加ticket
        const $ticketForm = $('#ticket-form');
        $ticketForm.length > 0 && this.initTicket($ticketForm);
        this.initServerTips();
        this.initCommonStyle();
        this.$document.trigger('qimify.init');
        this.booted = true;
        this.$document.trigger('qimify.boot');
    };

    reboot(){
        this.booted = false;
        this.boot();
    }

    initTicket($ticketForm){
        new Ticket($ticketForm);
    }

    /**
     * 初始化基本样式
     */
    initCommonStyle(){
        $('[data-toggle="tooltip"]').tooltip();
        $('.selectwrapper').selectpicker();
    }

    /**
     * 登录框
     */
    initLoginForm($installAppForm){
        const $shop = $installAppForm.find(':input[name="shop"]');
        $shop.on('change', function(){
            const shop = $.trim($shop.val());
            if (shop.length === 0) {
                return false;
            }
            const result = shop.match(/\/\/(.*myshopify.com)/);
            if (result) {
                $shop.val(result[1]);
            }
        });
    }

    /**
     * 联系我们
     * @param $contactUs
     */
    initContactUs($contactUs){
        const contactUsDialog = new ContactUs();
        $contactUs.on('click', ()=>contactUsDialog.show());
    }

    initServerTips(){
        if (!window.qimifyTips) {
            return;
        }
        window.qimifyTips.forEach((tip)=>{
            if (typeof tip === 'object') {
                qimify.notify.tips(tip.text, tip.type || 'warning');
            } else {
                qimify.notify.tips(tip, 'warning');
            }
        });
    }


    initPjax(){
        if (!window.enablePjax) {
            return;
        }
        const pjax = new Pjax({
            elements: ['a:not(.not-pjax)', 'form:not(.not-pjax)'],
            selectors: [
                "title",
                "meta[name=description]",
                '.content-wrapper',
            ]
        });
        this.$document.on('pjax:send', function() {
            qimify.loadingBar.start();
        });
        this.$document.on('pjax:complete', () =>{
            qimify.loadingBar.done();
            this.reboot();
        });
    }
}

new Application();